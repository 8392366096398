export default {
  $vuetify: {
    back: 'Back',
    home: 'Home',
    detail: 'More Detail',
    noData: 'No data at present',
    noPdf: 'There is no specification at present.',
    noAttachment: 'There is no attachment at present.',
    downloadAttachment: 'Attachments Download',
    product: {
      name: 'Products',
      title: 'All Products',
      more: 'More Products',
      pdf: 'Specification',
      support: 'Supports',
      article: 'Related Article',
      attachment: 'Attachment Files'
    },
    case: {
      title: 'Application Cases',
      more: 'More Cases',
    },
    support: {
      title: 'Supports',
      more: 'More Content',
      watch: 'To Watch'
    },
    news: {
      title: 'News',
      more: 'More News'
    },
    about: {
      title: 'About Us',
      more: 'More Detail'
    },
    friend: {
      title: 'Friendly Link',
    },
    search: {
      none: 'No query result is available',
      result: 'Search Result',
      history: 'Recent Search History',
      lookingFor: 'What Are You LookingFor ?',
    },

  },
}